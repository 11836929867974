import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box,} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ChakraDatePicker, InputFormik, NumberInputFormik, RadioGroupChakraFormik,} from '../../Formik';
import ChakraTagInput from '../../Formik/ChakraTagInput';
import {Option} from '../../Formik/SelectFormik';
import {StepSampleInformationForm, useSampleInformation,} from '../hooks/useSampleInformation';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';
import {checkSpecialCharacters} from "../../../stores/data";

export const StepSampleInformation: React.FC<
  StepTumorSampleRegistrationProps<StepSampleInformationForm>
> = observer(
  ({
     dataStore,
     edit,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<StepSampleInformationForm>) => {
    const [t] = useTranslation();
    const {initialValuesForm, onSubmit, validationSchema} = useSampleInformation(dataStore);

    useEffect(() => {
    }, [dataStore.sampleTypeOptions]);

    return (
      <AccordionItem pb={4}>
        {({isExpanded}) => (
          <>
            <h2>
              <AccordionButton>
                <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                  {t('tumorRegistrationProcess.sampleInfo.title')}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px"/>
                ) : (
                  <AddIcon fontSize="12px"/>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={10} background="#F6F7FC" pb={4}>
              <Formik
                initialValues={initialValuesForm}
                onSubmit={onSubmit}
                innerRef={innerRef}
                validationSchema={validationSchema}
              >
                {({values, isValid, dirty, handleBlur}) => {
                  onValidate(edit ? isValid : isValid && dirty);
                  return (
                    <Form>
                      <RadioGroupChakraFormik
                        label={t('tumorRegistrationProcess.sampleInfo.labels.sampleTypeId')}
                        name="sampleTypeId"
                        options={dataStore.sampleTypeOptions.map<Option>(
                          ({id, name}) => ({
                              value: id.toString(),
                              label: t(`sampleTypes.${name.replace(" ", "_").toLowerCase()}`),
                          })
                        )}
                        isRequired={true}
                      />
                      <Box w="sm" mt="5">
                        <ChakraDatePicker
                          label={t('tumorRegistrationProcess.sampleInfo.labels.samplingDate')}
                          name="samplingDate"
                          isRequired={false}
                        />
                      </Box>
                      <Box w="sm" mt="5">
                        <ChakraTagInput
                          label={t('tumorRegistrationProcess.sampleInfo.labels.barcode')}
                          placeholder={t('tumorRegistrationProcess.sampleInfo.placeholders.barcode')}
                          name="barcodes"
                          isRequired={true}
                          onBlur={handleBlur}
                          onKeyDown={(e) => {checkSpecialCharacters(e)}}
                        />
                      </Box>

                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t('tumorRegistrationProcess.sampleInfo.labels.hospitalPaSampleId')}
                          name="hospitalPaSampleId"
                        />
                      </Box>
                      {dataStore.sampleTypeOptions.some(({name, id}) =>
                          ['Isolated DNA', 'FFPE'].includes(name) && id === +values.sampleTypeId)
                        && (
                          <Box mt="5" w="sm">
                            <NumberInputFormik
                              label={t('tumorRegistrationProcess.sampleInfo.labels.concentration')}
                              placeholder={t('tumorRegistrationProcess.sampleInfo.placeholders.concentration')}
                              name="concentration"
                              isRequired={true}
                            />
                          </Box>
                        )}
                    </Form>
                  );
                }}
              </Formik>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
