import {BiopsyLocation, BiopsySiteInformation, BiopsySubLocation,} from '@hmf/portal-api-client';
import * as Yup from 'yup';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {Data} from '../../../stores/data';
import {useTranslation} from "react-i18next";

export interface StepBiopsySiteInfoForm {
  biopsyLocation?: string;
  biopsySubLocation?: string;
  lateralisation?: string;
  isPrimaryTumor?: string;
}

export const useBiopsySiteInfo = (dataStore: Data) => {
  const [t] = useTranslation();
  const validationSchema = Yup.object().shape({
    biopsyLocation: Yup.string().required(`${t("validations.required")}`),
    biopsySubLocation: Yup.string().required(`${t("validations.required")}`),
  });

  const initialValuesForm: StepBiopsySiteInfoForm = {
    biopsyLocation:
      dataStore.tumorRegistrationProcess.biopsySiteInfo?.biopsyLocationId.toString(),
    biopsySubLocation:
      dataStore.tumorRegistrationProcess.biopsySiteInfo?.biopsySubLocationId.toString(),
    lateralisation:
    dataStore.tumorRegistrationProcess.biopsySiteInfo?.lateralisation,
    isPrimaryTumor: '',
  };

  if (dataStore.tumorRegistrationProcess.biopsySiteInfo?.id) {
    if (dataStore.tumorRegistrationProcess.biopsySiteInfo != null) {
      const isPrimaryTumorValue =
        dataStore.tumorRegistrationProcess.biopsySiteInfo?.isPrimaryTumor;
      initialValuesForm.isPrimaryTumor =
        isPrimaryTumorValue === undefined ? 'unknown' : `${isPrimaryTumorValue}`;
    }
  }

  const [search, setSearch] = useState<string>('');

  const [biopsyLocations, setBiopsyLocations] = useState<BiopsyLocation[]>([]);
  const [biopsySubLocations, setBiopsySubLocations] = useState<
    BiopsySubLocation[]
  >([]);

  const onSubmit = (values) => {
    let biopsySite: BiopsySiteInformation = {
      biopsyLocationId: values.biopsyLocation,
      biopsySubLocationId: values.biopsySubLocation,
      isPrimaryTumor:
        values.isPrimaryTumor === 'unknown' || values.isPrimaryTumor === ''
          ? undefined
          : values.isPrimaryTumor === 'true',
    };

    if (values.lateralisation) {
      biopsySite.lateralisation = values.lateralisation;
    }

    if (dataStore.tumorRegistrationProcess.biopsySiteInfo?.id) {
      biopsySite.id = dataStore.tumorRegistrationProcess.biopsySiteInfo?.id;
      dataStore.updateBiopsySiteInformation(
        dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
        dataStore.tumorRegistrationProcess.biopsySiteInfo?.id,
        biopsySite
      );
      dataStore.handleTumorRegistrationProcess(
        dataStore?.tumorRegistrationProcess.tumorRegistration?.id
      );
    } else {
      dataStore.insertBiopsySiteInformation(
        dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
        biopsySite
      );
      dataStore.handleTumorRegistrationProcess(
        dataStore?.tumorRegistrationProcess.tumorRegistration?.id
      );
    }
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  const onChangeBiopsyLocation = (event: ChangeEvent<HTMLInputElement>) => {
    dataStore.fetchBiopsySubLocations(Number(event.target.value));
    setSearch('');
  };

  const onChangeBiopsySubLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch('');
  };

  const initState = useCallback(() => {
    if (dataStore.tumorRegistrationProcess.biopsySiteInfo?.id) {
      dataStore.fetchBiopsySubLocations(
        Number(
          dataStore.tumorRegistrationProcess.biopsySiteInfo.biopsyLocationId
        )
      );
    }
  }, [dataStore]);

  useEffect(() => {
    dataStore.fetchBiopsyLocations();
    initState();
  }, [dataStore, initState]);

  useEffect(() => {
    setBiopsyLocations(dataStore.biopsyLocations);
  }, [dataStore.biopsyLocations]);

  useEffect(() => {
    setBiopsySubLocations(dataStore.biopsySubLocations);
  }, [dataStore.biopsySubLocations]);

  return {
    initialValuesForm,
    biopsyLocations,
    biopsySubLocations,
    onSubmit,
    initState,
    search,
    onChangeSearch,
    onChangeBiopsyLocation,
    onChangeBiopsySubLocation,
    validationSchema
  };
};
