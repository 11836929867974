import {ExpandedIndex, useDisclosure} from '@chakra-ui/react';
import {FormikProps} from 'formik';
import {RefObject, useEffect, useRef, useState} from 'react';
import {Data} from '../../../stores/data';
import {delay} from '../../../utils';
import {StepBiopsySiteInfoForm} from './useBiopsySiteInfo';
import {StepConsentForm} from './useConsent';
import {StepDiagnosticPatientForm} from './useDiagnosticPatientInfo';
import {StepSampleInformationForm} from './useSampleInformation';
import {StepRequestForm} from './useStepRequest';
import {StepTumorForm} from './useStepTumorType';

export const useTumorSampleRegistration = (
  dataStore: Data,
  patientRegistrationId?: number
) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [messageSaving, setMessageSaving] = useState<string>('modals.saving.saving');

  const tumorRegistrationSteps = {
    REGISTRATION_SETUP: useRef<FormikProps<StepRequestForm>>(null),
    PATIENT_CONSENT: useRef<FormikProps<StepConsentForm>>(null),
    DIAGNOSTIC_PATIENT_INFORMATION:
      useRef<FormikProps<StepDiagnosticPatientForm>>(null),
    TUMOR_TYPE: useRef<FormikProps<StepTumorForm>>(null),
    BIOPSY_SITE_INFO: useRef<FormikProps<StepBiopsySiteInfoForm>>(null),
    SAMPLE_DETAILS: useRef<FormikProps<StepSampleInformationForm>>(null),
  };

  const [defaultIndex, setDefaultIndex] = useState<number>();
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<string>('REGISTRATION_SETUP');

  useEffect(() => {
    dataStore.fetchHospitalOptions();
    dataStore.handleTumorRegistrationProcess(
      dataStore.keyRegisterForm.reset ? undefined : patientRegistrationId
    );
  }, [dataStore, patientRegistrationId]);

  useEffect(() => {
    dataStore.tumorRegistrationProcess.steps.forEach((step, index) => {
      if (step.active && step.shouldRender) {
        setDefaultIndex(index);
        setCurrentStep(step.step);
      }
    });
    let stepEdit = dataStore.tumorRegistrationProcess.steps.some(
      (step) => step.edit
    );
    if (dataStore.isTheAccordionClosed && !stepEdit) {
      setDefaultIndex(undefined);
    }
  }, [
    dataStore.tumorRegistrationProcess.steps,
    dataStore.isTheAccordionClosed,
  ]);

  const completeLater = async () => {
    saveSteps();
    await onSaving();
    onCancelProcess();
  };

  const continueButton = () => {
    saveSteps();
  };

  async function saveSteps() {
    for (const step in tumorRegistrationSteps) {
      const currentRefForm = tumorRegistrationSteps[step]?.current;
      if (currentRefForm && currentRefForm.touched) {
        const errors = await currentRefForm.validateForm();
        if (Object.keys(errors).length === 0) {
          currentRefForm.handleSubmit();
        } else {
          console.debug("Form: " + step + " has validation errors:", errors);
        }
      }
    }
  }

  const onCancelProcess = () => {
    dataStore.resetTumorRegistrationOptions();
    dataStore.showTumorRegistrationTable(true);
    dataStore.closeAccordionWizard(false);
    dataStore.clearTumorRegistrationProcess();
    dataStore.setResetForm(false);
  };

  const onChangeAccordion = (index: ExpandedIndex) => {
    let currentIndexAux;

    if (index[0] !== undefined) {
      currentIndexAux = index[0];
    }

    if (index[0] !== undefined && index[1] !== undefined) {
      currentIndexAux = index[1];
    }

    setDefaultIndex(currentIndexAux);
    if (
      currentIndexAux !== undefined &&
      getCurrentStepInData(currentIndexAux).step !== 'REGISTRATION_SETUP'
    ) {
      dataStore.forceTumorRegistrationStep(currentIndexAux);
    } else {
      setIsDisabledSubmit(true);
      dataStore.forceTumorRegistrationStep(90);
    }
  };

  const onSaving = async () => {
    onCLoseModalCompleteLater();
    setMessageSaving('modals.saving.saving');
    onOpenModalSaving();

    setSaving(true);
    await delay(1600);
    setSaving(false);
    setMessageSaving('modals.saving.checking');
    await delay(1200);
    onCLoseModalSaving();
  };

  const onFinishRegister = async () => {
    await saveSteps(); // to be sure all last information is also stored
    setMessageSaving('modals.saving.checking');
    onOpenModalSaving();
    setSaving(true);
    let tumorRegistration = dataStore.tumorRegistrationProcess.tumorRegistration;
    tumorRegistration!.isComplete = true;
    await dataStore.completeTumorRegistration(
      dataStore.tumorRegistrationProcess.tumorRegistration?.id!
    );
    onCLoseModalSaving();
    onOpenModalFinish();
  };

  const onSendRegister = async () => {
    onCLoseModalFinish();
    dataStore.showTumorRegistrationTable(true);
    dataStore.setResetForm(false);
    dataStore.closeAccordionWizard(false);
    dataStore.clearTumorRegistrationProcess();
    dataStore.forceTumorRegistrationStep(0);
  };

  const onBackModal = () => {
    dataStore.showTumorRegistrationTable(true);
    dataStore.closeAccordionWizard(false);
    dataStore.clearTumorRegistrationProcess();
    dataStore.forceTumorRegistrationStep(0);
    dataStore.setResetForm(false);
  };

  const onNewRegister = () => {
    dataStore.resetFormKey();
    dataStore.clearTumorRegistrationProcess();
    dataStore.closeAccordionWizard(false);
    onCLoseModalFinish();
    dataStore.handleTumorRegistrationProcess();
  };

  const {
    isOpen: isOpenModalCompleteLater,
    onOpen: onOpenModalCompleteLater,
    onClose: onCLoseModalCompleteLater,
  } = useDisclosure();

  const {
    isOpen: isOpenModalSaving,
    onOpen: onOpenModalSaving,
    onClose: onCLoseModalSaving,
  } = useDisclosure();
  const {
    isOpen: isOpenModalFinish,
    onOpen: onOpenModalFinish,
    onClose: onCLoseModalFinish,
  } = useDisclosure();

  const {
    isOpen: isOpenCancel,
    onOpen: onOpenCancel,
    onClose: onCloseCancel,
  } = useDisclosure();
  const cancelRef = useRef();

  const getCurrentRefFormRequest = (
    currentStep: string
  ): RefObject<
    FormikProps<
      StepRequestForm
      | StepTumorForm
      | StepConsentForm
      | StepSampleInformationForm
      | StepBiopsySiteInfoForm
      | StepDiagnosticPatientForm
    >
  > => {
    return tumorRegistrationSteps[currentStep];
  };

  const getCurrentStepInData = (currentIndex: number) => {
    return dataStore.tumorRegistrationProcess.steps[currentIndex];
  };

  return {
    defaultIndex: defaultIndex,
    currentStep,
    isDisabledSubmit,
    setIsDisabledSubmit,
    getCurrentRefFormRequest,
    stepRequestForm: tumorRegistrationSteps,
    onCancelProcess,
    saving,
    completeLater,
    onOpenCancel,
    isOpenCancel,
    onCloseCancel,
    cancelRef,
    continueButton,
    onBackModal,
    onNewRegister,
    onChangeAccordion,
    messageSaving,
    onSendRegister,
    isOpenModalCompleteLater,
    onOpenModalCompleteLater,
    onCLoseModalCompleteLater,
    isOpenModalFinish,
    onOpenModalFinish,
    onCLoseModalFinish,
    isOpenModalSaving,
    onFinishRegister,
  };
};
