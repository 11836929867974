import { useRef, useState } from 'react';
import Header from './components/Header';
import {
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons';

import PROCESSED from './assets/icons/results.svg';
import REFERENCE_REGISTRATION from './assets/icons/reference-sample-registration-icon.svg';
import TUMOR_REGISTRATION from './assets/icons/tumor-sample-registration-icon.svg';

import { Data } from './stores/data';
import Processed from './components/ReportPage';

import { useTranslation } from 'react-i18next';
import { Auth, getAttribute, DISPLAY_NAME_ATTRIBUTE } from './stores/auth';
import { motion } from 'framer-motion';
import { Alert } from './components/Alert';
import ErrorModal from './components/ErrorModal';
import { observer } from 'mobx-react-lite';
import TumorSampleRegistrationPage from './components/TumorSampleRegistrationPage';
import ReferenceSampleRegistrationPage from './components/ReferenceSampleRegistrationPage';
import ModalConfidentialInfo from './components/Modals/ModalConfidentialInfo';
import { RolesRolesEnum } from '@hmf/portal-api-client';
import { action } from 'mobx';

const ICONS = {
  PROCESSED,
  REFERENCE_REGISTRATION,
  TUMOR_REGISTRATION
};

export const Layout = observer(
  ({
    auth,
    children,
    dataStore
  }: {
    auth: Auth;
    children: any;
    dataStore: Data;
  }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [t] = useTranslation();
    const [expand, setExpand] = useState<boolean>(false);
    const [isConfidentialModalOpen, setIsConfidentialModalOpen] =
      useState<boolean>(true);

    const cancelRef = useRef();

    const navBarTitles: {
      text: string;
      icon: string;
      role: RolesRolesEnum;
    }[] = t('navBar', {
      returnObjects: true,
    });

    const displayName = getAttribute(auth.user!, DISPLAY_NAME_ATTRIBUTE);

    const onConfirm = () => auth.logOut();

    const closeErrorModal = action(() => {
      dataStore.statusError = null;
    });

    return (
      <>
        <Tabs orientation="vertical">
          <motion.div layout transition={{ duration: 0.1 }}>
            <TabList
              backgroundColor="#304B99"
              borderColor="#304B99"
              color={'white'}
              h="100vh"
              w={expand ? '22vh' : 'initial'}
            >
              <Flex p={{ base: 6 }} fontSize={28} justify={'center'}>
                <HamburgerIcon
                  onClick={() => setExpand(!expand)}
                  __css={{ cursor: 'pointer' }}
                />
              </Flex>

              {navBarTitles
                .filter((title) => dataStore.identityRoles.has(title.role))
                .map((navBarTitle, i) => (
                  <Tab
                    p={2}
                    _selected={{
                      color: 'white',
                      borderColor: 'white',
                      borderLeftWidth: 4,
                    }}
                    onClick={() => {
                      if (navBarTitle.icon === 'TUMOR_REGISTRATIONS') {
                        if (!dataStore.isTumorRegistrationTable) {
                          dataStore.showTumorRegistrationTable(true);
                          dataStore.clearTumorRegistrationProcess();
                        }
                      }
                    }}
                    key={i}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyItems={expand ? 'start' : 'center'}
                      w={expand ? '100%' : 'initial'}
                    >
                      <img
                        src={ICONS[navBarTitle.icon]}
                        alt=""
                        width={35}
                        height={45}
                        style={{ margin: '10px' }}
                      />
                      {expand && (
                        <Text mr={15} fontSize="14px">
                          {navBarTitle.text}
                        </Text>
                      )}
                    </Stack>
                  </Tab>
                ))}
            </TabList>
          </motion.div>

          <TabPanels>
            {dataStore.identityRoles.has('RESULTS') && (
              <TabPanel style={{ padding: 0 }} height="100vh">
                <Header
                  displayName={displayName}
                  height="10%"
                  onClickSignOut={onOpen}
                />
                <Processed dataStore={dataStore} height="90%" />
              </TabPanel>
            )}
            {dataStore.identityRoles.has('BLOOD_REGISTRATION') && (
              <TabPanel style={{ padding: 0 }} height="100vh">
                <Header
                  displayName={displayName}
                  height="10%"
                  onClickSignOut={onOpen}
                />
                <ReferenceSampleRegistrationPage height="90%" dataStore={dataStore} />
              </TabPanel>
            )}
            {dataStore.identityRoles.has('TUMOR_REGISTRATION') && (
              <TabPanel style={{ padding: 0 }} height="100vh">
                <Header
                  displayName={displayName}
                  height="10%"
                  onClickSignOut={onOpen}
                />
                <TumorSampleRegistrationPage height="90%" dataStore={dataStore} />
              </TabPanel>
            )}
            <TabPanel style={{ padding: 0 }} height="100vh">
              <Header
                displayName={displayName}
                height="10%"
                onClickSignOut={onOpen}
              />
              {children}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <ErrorModal
          isOpen={!!dataStore.statusError}
          onClose={closeErrorModal}
          statusError={dataStore.statusError}
        />

        <ModalConfidentialInfo
          isOpen={isConfidentialModalOpen}
          onClose={() => setIsConfidentialModalOpen(false)}
          message={t('modals.confidentialInformation.text')}
        />

        <Alert
          isOpen={isOpen}
          onConfirm={onConfirm}
          cancelRef={cancelRef}
          onClose={onClose}
          title={t('modals.signOut.title')}
          text={t('modals.signOut.message')}
        />
      </>
    );
  }
);
