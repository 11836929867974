import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box,} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import {ChakraDatePicker, InputFormik, RadioGroupChakraFormik,} from '../../Formik';
import {StepDiagnosticPatientForm, useDiagnosticPatientInfo} from '../hooks/useDiagnosticPatientInfo';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';
import {DiagnosticPatientInformationGenderEnum} from '@hmf/portal-api-client';
import {useTranslation} from 'react-i18next';

const StepDiagnosticPatientInfo: React.FC<
  StepTumorSampleRegistrationProps<StepDiagnosticPatientForm>
> = observer(
  ({
     dataStore,
     edit,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<StepDiagnosticPatientForm>) => {
    const {initialValuesForm, onSubmit, isDisabled, validationSchema} =
      useDiagnosticPatientInfo(dataStore);
    const [t] = useTranslation();

    return (
      <AccordionItem pb={4}>
        {({isExpanded}) => (
          <>
            <h2>
              <AccordionButton>
                <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                  {t('tumorRegistrationProcess.diagnosticPatientInfo.title')}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px"/>
                ) : (
                  <AddIcon fontSize="12px"/>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={10} background="#F6F7FC">
              <Formik
                innerRef={innerRef}
                initialValues={initialValuesForm}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({isValid, dirty, setFieldValue}) => {
                  onValidate(isValid);
                  return (
                    <Form>
                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.initials'
                          )}
                          name="initials"
                          isDisabled={isDisabled}
                          //isRequired={true}
                        />
                      </Box>
                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.birthSurname'
                          )}
                          name="birthSurname"
                          isDisabled={isDisabled}
                          //isRequired={true}
                        />
                      </Box>
                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.currentSurname'
                          )}
                          name="currentSurname"
                          isDisabled={isDisabled}
                          //isRequired={true}
                        />
                      </Box>

                      <Box w="sm" mt="5">
                        <ChakraDatePicker
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.dateOfBirth'
                          )}
                          name="dateOfBirth"
                          disabled={isDisabled}
                        />
                      </Box>

                      <Box mt="5">
                        <RadioGroupChakraFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.gender'
                          )}
                          name="gender"
                          isDisabled={isDisabled}
                          options={Object.entries(
                            DiagnosticPatientInformationGenderEnum
                          ).map(([key, value]) => ({
                            label: t(`genders.${value.toLowerCase()}`),
                            value,
                          }))}
                        />
                      </Box>

                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.postalCode'
                          )}
                          placeholder={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.placeholders.postalCode'
                          )}
                          name="postalCode"
                          textTransform="uppercase"
                          _placeholder={{textTransform: 'none'}}
                          onKeyUp={(e) =>
                            setFieldValue(
                              'postalCode',
                              ((e.target.value as string) || '').toUpperCase(),
                              true
                            )
                          }
                          isDisabled={isDisabled}
                          //isRequired={true}
                        />
                      </Box>

                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.nameTreatingPhysician'
                          )}
                          name="nameTreatingPhysician"
                          isDisabled={isDisabled}
                          //isRequired={true}
                        />
                      </Box>
                      <Box mt="5" w="sm">
                        <InputFormik
                          label={t(
                            'tumorRegistrationProcess.diagnosticPatientInfo.labels.emailTreatingPhysician'
                          )}
                          name="emailTreatingPhysician"
                          isDisabled={isDisabled}
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);

export default StepDiagnosticPatientInfo;
