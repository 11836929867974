import {Data} from '../../../stores/data';
import * as Yup from 'yup';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {TumorTypeInformation} from '@hmf/portal-api-client';
import {useTranslation} from "react-i18next";

export interface StepTumorForm {
  tumorLocation?: string;
  tumorType?: string;
  tumorDetails?: string;
  primaryTumorKnown: string;
}

export const useStepTumorType = (dataStore: Data) => {
  const [t] = useTranslation()
  const validationSchema = Yup.object().shape({
    tumorLocation: Yup.string().when('primaryTumorKnown', {
      is: 'true',
      then: Yup.string().required(`${t("validations.required")}`),
    }),
    tumorType: Yup.string()
      .when('primaryTumorKnown', {
        is: 'true',
        then: Yup.string().required(`${t("validations.required")}`),
      })
      .when('primaryTumorKnown', {
        is: 'CUP',
        then: Yup.string().required(`${t("validations.required")}`),
      }),
    tumorDetails: Yup.string().when([], {
      is: () => dataStore.tumorDetails.length > 0,
      then: Yup.string().required(`${t("validations.required")}`),
    }),
    primaryTumorKnown: Yup.string().required(`${t("validations.required")}`),
  });

  function getPrimaryTumorKnownOption(tumorType: undefined | TumorTypeInformation): string {
    if (tumorType?.tumorLocation === dataStore.tumorLocationCup) {
      return "CUP"
    }
    return tumorType?.primaryTumorKnown === undefined || tumorType?.primaryTumorKnown  ? "true" : "false"
  }

  const initialValuesForm: StepTumorForm = {
    tumorLocation: dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocation,
    tumorType: dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorType,
    tumorDetails: dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorDetails,
    primaryTumorKnown: getPrimaryTumorKnownOption(dataStore.tumorRegistrationProcess.tumorTypeInfo)
  };

  const [search, setSearch] = useState<string>('');
  const [tumorLocations, setTumorLocations] = useState<string[]>([]);
  const [tumorTypes, setTumorTypes] = useState<string[]>([]);
  const [tumorDetails, setTumorDetails] = useState<string[]>([]);
  const [tumorLocation, setTumorLocation] = useState<string>('');

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
  };

  const onSubmit = (values: StepTumorForm) => {
    try {
      let primaryTumorKnown = false;
      if (values.primaryTumorKnown === 'CUP' || values.primaryTumorKnown === 'true') {
        primaryTumorKnown = true;
      }

      let tumorType: TumorTypeInformation = {
        primaryTumorKnown
      };

      if (tumorType.primaryTumorKnown) {
        tumorType.tumorLocation = values.tumorLocation;
        tumorType.tumorType = values.tumorType;
      }

      if (values.tumorDetails) {
        tumorType.tumorDetails = values.tumorDetails;
      }

      if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.id) {
        tumorType.id = dataStore.tumorRegistrationProcess.tumorTypeInfo?.id
        dataStore.updateTumorTypeInformation(
          dataStore.tumorRegistrationProcess.tumorRegistration?.id!,
          dataStore.tumorRegistrationProcess.tumorTypeInfo?.id,
          tumorType
        );
        dataStore.handleTumorRegistrationProcess(dataStore?.tumorRegistrationProcess.tumorRegistration?.id);
      } else {
        dataStore.insertTumorTypeInformation(dataStore.tumorRegistrationProcess.tumorRegistration?.id!, tumorType);
        dataStore.handleTumorRegistrationProcess(dataStore?.tumorRegistrationProcess.tumorRegistration?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePrimaryTumorKnown = () => {
    setSearch('');
  };

  const onChangeTumorLocationKnownCUP = () => {
    dataStore.fetchTumorTypes(dataStore.tumorLocationCup);
    setSearch('');
  };

  const onChangeTumorLocation = (event: ChangeEvent<HTMLInputElement>) => {
    const location = event.target.value;
    dataStore.fetchTumorTypes(location);
    setTumorLocation(location);
    setSearch('');
  };

  const onChangeTumorType = (event: ChangeEvent<HTMLInputElement>) => {
    const tumorType = event.target.value;
    dataStore.fetchTumorDetails(tumorLocation, tumorType);
    setSearch('');
  };

  const onChangeTumorDetails = (_event: ChangeEvent<HTMLInputElement>) => {
    setSearch('');
  };

  const initState = useCallback(() => {
    if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.id) {
      if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocation) {
        dataStore.fetchTumorTypes(dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocation);

        if (dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorType) {
          dataStore.fetchTumorDetails(
            dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorLocation,
            dataStore.tumorRegistrationProcess.tumorTypeInfo?.tumorType
          );
        }
      }
    }
  }, [dataStore]);

  useEffect(() => {
    dataStore.fetchTumorLocations();
    initState();
  }, [dataStore, initState]);

  useEffect(() => {
    setTumorLocations(dataStore.tumorLocations);
  }, [dataStore.tumorLocations]);

  useEffect(() => {
    setTumorTypes(dataStore.tumorTypes);
  }, [dataStore.tumorTypes]);

  useEffect(() => {
    setTumorDetails(dataStore.tumorDetails);
  }, [dataStore.tumorDetails]);

  return {
    initialValuesForm,
    tumorLocations,
    tumorTypes,
    tumorDetails,
    onSubmit,
    search,
    initState,
    onChangeSearch,
    onChangePrimaryTumorKnown,
    onChangeTumorLocationKnownCUP,
    onChangeTumorLocation,
    onChangeTumorDetails: onChangeTumorDetails,
    onChangeTumorType,
    validationSchema
  };
};
