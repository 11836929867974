import {ReferenceRegistrationSampleTypeEnum} from '@hmf/portal-api-client';
import {Data, LEGAL_CHARACTER_REGEX} from '../../../stores/data';
import * as Yup from "yup";
import {studyIdValidation} from "../../../utils";
import {useTranslation} from "react-i18next";

export interface ReferenceSampleFormDto {
  hospitalId?: number;
  studyIdPrefix: string;
  patientId: string;
  currentSurname: string;
  dateOfBirth?: Date;
  samplingDate?: Date;
  gender: string;
  barcode: string;
  sampleType: string;
  concentration: string;
  contractCode: string;
}

export const useReferenceSampleForm = (dataStore: Data) => {
  const [t] = useTranslation()

  const validationSchema = Yup.object().shape({
    hospitalId: Yup.string().required(`${t('validations.required')}`),
    barcode: Yup.string()
      .required(`${t('validations.required')}`)
      .matches(LEGAL_CHARACTER_REGEX, `${t('validations.illegalCharacterError')}`),
    sampleType: Yup.string().required(`${t('validations.required')}`),
    concentration: Yup.number().when('sampleType', {
      is: (sampleType: string) =>
        sampleType?.includes('DNA') || sampleType?.includes('FFPE'),
      then: Yup.number()
        .required(`${t('validations.required')}`)
        .typeError(`${t('validations.concentrationFormatError')}`),
    }),
    contractCode: Yup.string().required(`${t('validations.required')}`),
    patientId: Yup.string()
      .required(`${t('validations.required')}`)
      .matches(LEGAL_CHARACTER_REGEX, `${t('validations.illegalCharacterError')}`)
      .when('contractCode', {
        is: (contractCode: string) => !dataStore.isDiagnostic(contractCode),
        then: studyIdValidation(t),
      })
  });

  const initialValues: ReferenceSampleFormDto = {
    hospitalId: undefined,
    studyIdPrefix: '',
    patientId: '',
    currentSurname: '',
    dateOfBirth: undefined,
    samplingDate: undefined,
    gender: '',
    barcode: '',
    sampleType: ReferenceRegistrationSampleTypeEnum.FreshBlood,
    concentration: '',
    contractCode: ''
  };

  const {hospitalOptions, contractOptions} = dataStore;

  const onSubmit = () => {}
  const onChangeHospital = async (hospitalId: number) => {
    await dataStore.fetchAllContractOptions(hospitalId)
  }

  const showConcentration = (sampleType: string): boolean => sampleType.includes('DNA') || sampleType.includes('FFPE');

  return {
    initialValues,
    onSubmit,
    hospitalOptions,
    validationSchema,
    showConcentration,
    onChangeHospital
  }
};
