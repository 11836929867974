import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {AccordionButton, AccordionItem, AccordionPanel, Box, Flex, FormLabel, Input,} from '@chakra-ui/react';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {RadioGroupChakraFormik} from '../../Formik';
import {StepTumorForm, useStepTumorType} from '../hooks/useStepTumorType';
import {StepTumorSampleRegistrationProps} from '../interfaces/Steps';

export const StepTumorType: React.FC<
  StepTumorSampleRegistrationProps<StepTumorForm>
> = observer(
  ({
     dataStore,
     onValidate,
     active,
     innerRef,
   }: StepTumorSampleRegistrationProps<StepTumorForm>) => {
    const [t] = useTranslation();
    const {
      initialValuesForm,
      tumorLocations,
      tumorDetails,
      tumorTypes,
      search,
      onSubmit,
      onChangeTumorLocationKnownCUP,
      onChangePrimaryTumorKnown,
      onChangeTumorLocation,
      onChangeTumorType,
      onChangeTumorDetails,
      onChangeSearch,
      validationSchema
    } = useStepTumorType(dataStore);

    return (
      <AccordionItem pb={4}>
        {({isExpanded}) => (
          <>
            <h2>
              <AccordionButton>
                <Box flex="1" marginLeft={!active ? '2' : '6'} textAlign="left">
                  {t('tumorRegistrationProcess.tumorType.title')}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px"/>
                ) : (
                  <AddIcon fontSize="12px"/>
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel paddingX={10} background="#F6F7FC" pb={4}>
              <Formik
                innerRef={innerRef}
                initialValues={initialValuesForm}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange={true}
              >
                {({values, isValid, setFieldValue, handleChange}) => {
                  onValidate(isValid);
                  return (
                    <Form>
                      <Flex align="center">
                        <RadioGroupChakraFormik
                          key="primaryTumorKnown"
                          label={t('tumorRegistrationProcess.tumorType.labels.primaryTumorKnown')}
                          name="primaryTumorKnown"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value === 'CUP') {
                              setFieldValue('tumorLocation', dataStore.tumorLocationCup, true);
                              setFieldValue('tumorType', '', true);
                              setFieldValue('tumorDetails', '', true);
                              onChangeTumorLocationKnownCUP();
                            } else {
                              setFieldValue('tumorLocation', '', true);
                              setFieldValue('tumorType', '', true);
                              setFieldValue('tumorDetails', '', true);
                            }
                            handleChange(e);
                            onChangePrimaryTumorKnown();
                          }}
                          options={[
                            {
                              label: t('tumorTypeKnownOptions.yes'),
                              value: 'true',
                            },
                            {
                              label: t('tumorTypeKnownOptions.no'),
                              value: 'false',
                            },
                            {
                              label: t('tumorTypeKnownOptions.cup'),
                              value: 'CUP',
                            },
                          ]}
                          isRequired={true}
                        />
                      </Flex>
                      {['CUP', 'true'].includes(values.primaryTumorKnown) && (
                        <>
                          <Box w="40%" mb="5">
                            <FormLabel fontSize="sm" htmlFor="Search">
                              {t(
                                'tumorRegistrationProcess.tumorType.labels.search'
                              )}
                            </FormLabel>
                            <Input
                              value={search}
                              onChange={onChangeSearch}
                              variant="outline"
                              borderColor="gray"
                              focusBorderColor="gray"
                            />
                          </Box>
                          <Flex align="center">
                            <RadioGroupChakraFormik
                              key="tumorLocation"
                              label={t(
                                'tumorRegistrationProcess.tumorType.labels.tumorLocation'
                              )}
                              name="tumorLocation"
                              onChange={(e) => {
                                setFieldValue('tumorType', '', true);
                                setFieldValue('tumorDetails', '', true);
                                handleChange(e);
                                onChangeTumorLocation(e);
                              }}
                              search={search}
                              options={tumorLocations
                                .map((location) => ({
                                  label: t(location, {'ns': 'tumor'}),
                                  value: location,
                                }))
                                .sort((a, b) => a.label.localeCompare(b.label))}
                              isRequired={true}
                              grid={true}
                            />
                          </Flex>
                          {values.tumorLocation && (
                            <Flex align="center">
                              <RadioGroupChakraFormik
                                mt="5"
                                label={t(
                                  'tumorRegistrationProcess.tumorType.labels.tumorType'
                                )}
                                name="tumorType"
                                search={search}
                                onChange={(e) => {
                                  handleChange(e);
                                  onChangeTumorType(e);
                                }}
                                options={tumorTypes
                                  .map((type) => ({
                                    label: t(type, {'ns': 'tumor'}),
                                    value: type,
                                  }))
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )}
                                isRequired={true}
                                grid={true}
                              />
                            </Flex>
                          )}
                          {values.tumorType && tumorDetails.length > 0 && (
                            <Flex align="center">
                              <RadioGroupChakraFormik
                                mt="5"
                                label={t(
                                  'tumorRegistrationProcess.tumorType.labels.tumorDetails'
                                )}
                                name="tumorDetails"
                                search={search}
                                onChange={onChangeTumorDetails}
                                options={tumorDetails
                                  .map((details) => ({
                                    label: t(details, {'ns': 'tumor'}),
                                    value: details,
                                  }))
                                  .sort((a, b) =>
                                    a.label.localeCompare(b.label)
                                  )}
                                isRequired={true}
                                grid={true}
                              />
                            </Flex>
                          )}
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
);
