import {TumorRegistration} from '@hmf/portal-api-client';
import {useCallback, useEffect, useState} from 'react';
import {Data, isSingleAnalysisType, LEGAL_CHARACTER_REGEX} from '../../../stores/data';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
import {studyIdValidation} from "../../../utils";

export interface StepRequestForm {
  hospitalId?: string;
  contractCode: string;
  analysisTypeId: string;
  patientId: string;
  studyIdPrefix: string;
  hospitalSampleLabel: string;
}

export const useStepRequest = (dataStore: Data) => {
  const [t] = useTranslation()

  const validationSchema = Yup.object().shape({
    hospitalId: Yup.string().required(`${t("validations.required")}`),
    contractCode: Yup.string().required(`${t("validations.required")}`),
    patientId: Yup.string()
      .matches(LEGAL_CHARACTER_REGEX, t("validations.illegalCharacterError"))
      .required(`${t("validations.required")}`)
      .when('contractCode', {
        is: (contractCodeToMatch: string) => {
          return !dataStore.contractOptions.find(
            ({contractCode}) => contractCode === contractCodeToMatch
          )?.samplesAreDiagnostic;
        },
        then: studyIdValidation(t)
      }),
    analysisTypeId: Yup.string().when({
      is: !isSingleAnalysisType(dataStore),
      then: Yup.string().required(`${t("validations.required")}`),
    }),
    hospitalSampleLabel: Yup.string().matches(LEGAL_CHARACTER_REGEX, t("validations.illegalCharacterError"))
  });

  const {hospitalOptions, contractOptions} = dataStore;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const initialValuesForm = {
    hospitalId: dataStore.tumorRegistrationProcess.tumorRegistration?.hospitalId.toString() ?? undefined,
    contractCode: dataStore.tumorRegistrationProcess.tumorRegistration?.contractCode ?? '',
    analysisTypeId: dataStore.tumorRegistrationProcess.tumorRegistration?.analysisTypeId.toString() ?? '',
    patientId: dataStore.tumorRegistrationProcess.tumorRegistration?.patientId ?? '',
    studyIdPrefix: '',
    hospitalSampleLabel: dataStore?.tumorRegistrationProcess.tumorRegistration?.hospitalSampleLabel ?? ''
  }

  const onChangeAnalysisType = (analysisTypeId: number, hospitalId: number) => {
    dataStore.fetchContractOptions(analysisTypeId, hospitalId);
  }

  const onChangeHospital = async (hospitalId: number) => {
    await dataStore.fetchAnalysisTypeOptions(hospitalId);
    if (isSingleAnalysisType(dataStore)) {
      await dataStore.fetchContractOptions(dataStore.analysisTypeOptions[0].id, hospitalId);
    }
  }

  const onSubmit = async (values: StepRequestForm) => {
    try {
      if (dataStore?.tumorRegistrationProcess.tumorRegistration?.id) {
        await updateTumorRegistration(values)
      } else {
        await insertTumorRegistration(values)
      }
      setIsDisabled(true);
    } catch (error) {
      console.log(error);
    }
  }

  const insertTumorRegistration = async (values: StepRequestForm) => {
    const samplesAreDiagnostic = dataStore.contractOptions.find(({contractCode}) => contractCode === values.contractCode)?.samplesAreDiagnostic
    const hospitalSampleLabel = values.hospitalSampleLabel === '' ? undefined : values.hospitalSampleLabel
    const tumorRegistration: TumorRegistration = {
      analysisTypeId: isSingleAnalysisType(dataStore) ? dataStore.analysisTypeOptions[0].id : Number(values.analysisTypeId),
      contractCode: values.contractCode,
      hospitalId: Number(values.hospitalId),
      patientId: samplesAreDiagnostic ? values.patientId : values.studyIdPrefix + values.patientId,
      isComplete: false,
      hospitalSampleLabel: hospitalSampleLabel
    };
    await dataStore.insertTumorRegistration(tumorRegistration);
    await dataStore.handleTumorRegistrationProcess(dataStore.tumorRegistrationProcess.tumorRegistration?.id);
  }

  const updateTumorRegistration = async (values:StepRequestForm) => {
    const tumorRegistration = dataStore?.tumorRegistrationProcess.tumorRegistration!
    tumorRegistration.hospitalSampleLabel = values.hospitalSampleLabel === '' ? undefined : values.hospitalSampleLabel;
    await dataStore.updateTumorRegistration(tumorRegistration.id!, tumorRegistration);
    await dataStore.handleTumorRegistrationProcess(dataStore?.tumorRegistrationProcess.tumorRegistration?.id);
  }

  const initState = useCallback(() => {
    if (dataStore?.tumorRegistrationProcess.tumorRegistration?.id) {
      setIsDisabled(true);
      dataStore.fetchAnalysisTypeOptions(dataStore?.tumorRegistrationProcess.tumorRegistration.hospitalId);
      dataStore.fetchContractOptions(
        dataStore?.tumorRegistrationProcess.tumorRegistration.analysisTypeId,
        dataStore?.tumorRegistrationProcess.tumorRegistration.hospitalId
      );
    }
  }, [dataStore]);

  useEffect(() => initState(), [dataStore, initState]);
  return {
    initialValuesForm,
    hospitalOptions,
    contractOptions,
    isDisabled,
    initState,
    onSubmit,
    onChangeHospital,
    onChangeAnalysisType,
    validationSchema
  };
};
