import * as Yup from 'yup';

export const parseDate = (date: Date) => {
  const [month, _l1, day, _l2, year] =
    // locale 'en' here is just used to get the full 2-digit values, it works with any locale
    new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(date);

  return `${year.value}-${month.value}-${day.value}`;
};

export const parseTime = (date: Date) => {
  const [hour, _l1, minute, _l2, second] =
    // locale 'en' here is just used to get the full 2-digit values, it works with any locale
    new Intl.DateTimeFormat('en', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).formatToParts(date);

  return `${hour.value}:${minute.value}:${second.value}`;
};

export const blobToBase64 = (blob: Blob): Promise<unknown> => {
  return new Promise((res, rej) => {
    const url = URL.createObjectURL(blob);

    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      URL.revokeObjectURL(url);
      res(reader.result);
    };
  });
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const studyIdValidation = (t) =>
  Yup.string()
    .when('studyIdPrefix', {
      is: (value) => value && value.trim() !== '', // If studyIdPrefix is NOT empty
      then: Yup.string().matches(
        /^\d{4}$/,
        `${t('validations.studyIdFormatError')}`
      ),
      otherwise: Yup.string().matches(
        /^[A-Z]{4}-[A-Z0-9]{2}-[A-Z0-9]{2}-\d{4}$/,
        `${t('validations.studyIdFormatError')}`
      )
    })
    .required(`${t('validations.required')}`)
